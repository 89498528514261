// libraries
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import { Pagination, Navigation } from "swiper"
import parse from 'html-react-parser'
import { Swiper, SwiperSlide } from "swiper/react"
import { useParams } from "react-router"
import { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next"

// components & functions
import { apiClient } from "../../../core/api_client"
import Breadcrumb from '../../../components/Breadcrumb'
import l from "../../../core/localize"
import ResizableImg from '../../../components/ResizableImg'
import { Routing } from "../../../core/routing"

// assets
import arrow1 from '../../../../assets/img/utils/slider-arrow-l-1.svg'
import arrow2 from '../../../../assets/img/utils/slider-arrow-r-1.svg'
import arrow_right from "../../../../assets/img/utils/arrow_right_orange.svg"
import date from '../../../../assets/img/utils/date.svg'
import fb from "../../../../assets/img/socials/facebook_light.svg"
import tw from "../../../../assets/img/socials/twitter_light.svg"

// styles
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"

const NewsPage = () => {
    const { newsId } = useParams()
    const [oneNews, setOneNews] = useState()
    const [isLoading, setLoading] = useState(true)
    const [slidesCount, setSlidesCount] = useState(10)
    const [zoomedImg, setZoomedImg] = useState()
    const [news, setNews] = useState([])
    const { t, i18n } = useTranslation()
    const currentLang = i18n.language.slice(0, 2)

    useEffect(() => {
        apiClient.get('/news/' + newsId)
            .then(function (response) {
                setOneNews(response.data)
                setLoading(false)
            })
    }, [newsId])

    useEffect(() => {
        apiClient.get('/news/newsType/1')
            .then(function (response) {
                setNews(response.data)
            })
    }, [])

    useEffect(() => {
        const content = document.getElementById('one-news-content');

        if (content) {
            const images = content.querySelectorAll('img');
            images.forEach(img => {
                img.addEventListener('click', () => {
                    console.log(img);
                    <ResizableImg clicked={true} children={img} />
                })
            });
        }
    }, [oneNews])

    const breadcrumb = [
        {
            id: 1,
            link: '/xeberler-ve-media/bizim-xeberler',
            title: t('common:our_news'),
            isActive: true
        },
        {
            id: 2,
            link: '/xeberler-ve-media/multimedia',
            title: t('common:multimedia'),
            isActive: false
        }
    ]

    const handleNextClick = () => {
        setSlidesCount(slidesCount + 1)
    }

    return (
        <>
            <div id="fb-root"></div>
            <script async defer crossOrigin="anonymous" src="https://connect.facebook.net/ru_RU/sdk.js#xfbml=1&version=v16.0" nonce="96T92Z3Y"></script>
            <div className="news-page block-bg1 pt-md-3 pt-4 pb-5 position-relative">
                <Breadcrumb data={breadcrumb} />

                <div className='container px-md-2 px-1 mt-5'>
                    {isLoading && <div className='d-flex justify-content-center align-items-center' style={{ height: '300px' }}><div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>}
                    {oneNews &&
                        <div className="news-item mt-4 px-md-5 px-1 pt-3 pb-5 block-bg-white position-relative">
                            <div className="d-flex flex-column align-items-center">
                                <h4 className="px-3 mt-2 w-75 text-center mx-auto">{l(oneNews, "name", currentLang)}</h4>
                                <div className="my-3" style={{ height: "6px", width: "180px", background: "#8CA2C2", borderRadius: "50px" }}></div>
                                <small className="news-date fw-normal px-4 py-2">{dayjs(oneNews.date).format('DD.MM.YYYY')}</small>
                            </div>
                            <div id='one-news-content' className="row mt-3 px-md-120 gx-2 pb-5" >
                                <div className='news-main-pic col-12 text-justify' style={{ lineHeight: "36px" }}><img src={Routing.generateFileRoute(l(oneNews, "file", currentLang))} alt={l(oneNews, "name", currentLang)} className='col-md-4 col-12' style={{ maxHeight: "300px" }} />{parse(l(oneNews, "content", currentLang))}</div>
                            </div>
                            <div className='d-flex justify-content-end mt-4 px-md-180'>
                                <div className="btn-share fb-share-button" data-href={window.location.href} data-layout="" data-size=""><a target="_blank" rel="noreferrer" href={"https://www.facebook.com/sharer/sharer.php?u=" + encodeURI(window.location.href) + "&amp src=sdkpreparse"} className="fb-xfbml-parse-ignore t-color4"><img src={fb} alt="..." />Paylaş</a></div>
                                <a href="https://twitter.com/share?ref_src=twsrc%5Etfw" className='btn-share ms-4' data-show-count="false"><img src={tw} alt="..." />Tweet</a><script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
                            </div>
                        </div>
                    }
                </div>

                <div className='image-box position-absolute'>

                </div>

                <div className='container position-relative'>
                    <h1 className="text text-center section-heading mt-5 mb-4">{t('common:other_news')}</h1>
                    <div className={"swiper-button-prev-n d-md-flex d-none " + (news.length > 4 ? "" : "d-none d-md-none")}
                        style={{
                            height: '120px',
                            left: '-4%',
                            top: '45%'
                        }}>
                        <img src={arrow1} alt="" />
                    </div>
                    <Swiper
                        slidesPerView={4}
                        spaceBetween={30}
                        loop={false}
                        navigation={{
                            nextEl: '.swiper-button-next-n',
                            prevEl: '.swiper-button-prev-n',
                        }}
                        modules={[Pagination, Navigation]}
                        id="news-page"
                        className="mySwiper mt-3 mb-3 row gx-2 pb-3 d-none d-md-block"
                    >
                        {news.filter(item => item.id.toString() !== newsId).slice(0, slidesCount).map(item =>
                            <SwiperSlide key={item.id}>
                                <Link to={"/xeberler-ve-media/bizim-xeberler/" + item.id} className='col-lg-3 my-4'>
                                    <div className="news-item news-item2 position-relative" style={{ background: "#FFFFFF", boxShadow: "2px 2px 2px 2px rgba(0, 0, 0, 0.25)" }}>
                                        <img src={Routing.generateFileRoute(l(item, "file", currentLang))} className="w-100" alt="..." style={{ maxHeight: "200px" }} />
                                        <div className="card-body px-3 pt-4 text-center d-flex flex-column" style={{ height: "220px" }}>
                                            <small className="news-date mx-auto"><img src={date} alt="date-icon" className='me-2' />{dayjs(item.date).format('DD.MM.YYYY')}</small>
                                            <p className="news-title mt-2">{l(item, "name", currentLang)}</p>
                                            <span className="mt-auto">
                                                <small className='m-0 highlight fw-bolder'>{t('common:show_details')}</small>
                                                <img className="ms-2" src={arrow_right} alt="" />
                                            </span>
                                        </div>
                                    </div>
                                </Link>
                            </SwiperSlide>
                        )}
                    </Swiper>
                    <div onClick={handleNextClick} className={"swiper-button-next-n d-md-flex d-none " + (news.length > 4 ? "" : "d-md-none d-none")} style={{
                        height: '120px',
                        right: '-5%',
                        top: '45%'
                    }}>
                        <img src={arrow2} alt="" />
                    </div>

                    {/* MOBILE SLIDER */}
                    <div className="row mt-2 mb-3 d-md-none">
                        {news.filter(item => item.id.toString() !== newsId).slice(0, 50).map(item =>
                            <Link key={item.id} to={"/xeberler-ve-media/bizim-xeberler/" + item.id} className='col-12 my-4'>
                                <div className="news-item" style={{ background: "#FFFFFF", boxShadow: "2px 2px 2px 2px rgba(0, 0, 0, 0.25)" }}>
                                    <img src={Routing.generateFileRoute(l(item, "file", currentLang))} className="w-100" alt="..." />
                                    <div className="card-body px-3 py-4 text-center">
                                        <small className="news-date mx-auto"><img src={date} alt="date-icon" className='me-2' />{dayjs(item.date).format('DD.MM.YYYY')}</small>
                                        <p className="news-title mt-2">{l(item, "name", currentLang)}</p>
                                        <span>
                                            <small className='m-0 highlight fw-bolder'>{t('common:show_details')}</small>
                                            <img className="ms-2" src={arrow_right} alt="" />
                                        </span>
                                    </div>
                                </div>
                            </Link>
                        )}
                    </div>
                    {/* MOBILE SLIDER */}
                </div>
            </div>
        </>
    )
}

export default NewsPage 

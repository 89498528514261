import { useState } from 'react'
import close from '../../assets/img/guzestli menzil/x.svg'

const ResizableImg = ({ children, clicked }) => {
    // const [height, setHeight] = useState(0);
    const [isClicked, setClicked] = useState(clicked);

    // useEffect(() => {
    //     var body = document.body,
    //         html = document.documentElement;

    //     setHeight(Math.max(body.scrollHeight, body.offsetHeight,
    //         html.clientHeight, html.scrollHeight, html.offsetHeight));
    // }, [document]);

    return (
        <>
            {/* <img src={src} className={className} style={style} alt={alt} onClick={_ => setClicked(true)}></img> */}
            {/* {children} */}
            {isClicked &&
                <div className={'position-absolute w-100 top-0 start-0 justify-content-center d-flex'} style={{ background: "rgba(34, 50, 74, 0.4)", zIndex: 10000, height: '100%' }}>
                    <div className='note-card rounded-4 text-black position-fixed pt-2 pb-4 px-3 my-auto' style={{
                        top: '10%', maxWidth: "75%", height: '700px', backgroundColor: "white"
                    }}>
                        <img className="d-flex ms-auto cursor" src={close} onClick={_ => setClicked(false)} alt='' />
                        <div className='h-100'>
                            {/* <img src={src} className='w-100' style={{ height: '90%' }} alt=''></img> */}
                            {children}
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default ResizableImg

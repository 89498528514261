import React from "react";
import { IAsyncData } from "../../../../../core/models";
import { IApartment } from "../../data/entities";
import { ROUTES } from "../../../../../routes";
import { ExtendedLink } from "../../../../../i18n";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface IProps {
  apartmentDetailBranch: IAsyncData<IApartment>;
  onPageChange: (params: any) => void;
}

const Title = styled.h4`
  text-align: center;
  margin: 20px 0;
  font-size: 1rem;
  color: white !important;
  padding: 1rem;
  background: #223a5e;
  border-radius: 22px;

  @media screen and (max-width: 1300px) {
    margin-bottom: 20px;
  }
`;

export const ApartmentTopNav: React.FC<IProps> = ({
  apartmentDetailBranch, onPageChange
}: IProps) => {

  return (
    <Title>
      <ExtendedLink to=""
        onClick={() => onPageChange({
          route: ROUTES.project,
        })}>
        <b className="t-color4">{`Bina ${apartmentDetailBranch.data?.buildingNumber} > `}</b>
      </ExtendedLink>

      <ExtendedLink to=""
        onClick={() => onPageChange({
          route: ROUTES.sector,
          param1: apartmentDetailBranch.data?.sectorId,
        })}
      >
        <b className="t-color4">{`Giriş ${apartmentDetailBranch.data?.entranceNumber}`}</b>
      </ExtendedLink>

      <ExtendedLink to=""
        onClick={() => onPageChange({
          route: ROUTES.floor,
          param1: apartmentDetailBranch.data?.sectorId,
          param2: apartmentDetailBranch.data?.floorId,
        })}
      >
        <b className="t-color4">{` > Mərtəbə ${apartmentDetailBranch.data?.floorNumber}`}</b>
      </ExtendedLink>

      <span>{` > Mənzil ${apartmentDetailBranch.data?.apartmentNumber}`}</span>
    </Title>
  );
};

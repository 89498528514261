import React, { useState, ReactNode, useEffect, useRef } from "react";
import { IShape } from "../entites";
import { Path } from "../path/path";
import { Maybe } from "../../../core/models";
import classes from "./shape_viewer.module.scss";
import classNames from "classnames";
import { invertShape, IPosition, calcShapeCoordinates } from "./utils";
import { IImage } from "../../../core/image";
import { ExtendedLink } from "../../../i18n";

const SVG_ID = "SHAPE_VIEWER_ID";

interface IProps {
  image: Maybe<IImage>;
  shapes: Maybe<IShape[]>;
  svgStyles?: React.CSSProperties;
  invertHover?: boolean;
  hoverColor: string;
  renderShapeLink: (shape: IShape) => string;
  renderShapeOnClickLink?: (shape: IShape) => void;
  renderShapeOverlayText?: (shape: IShape) => Maybe<string[]>;
  renderHovered?: (shape: IShape) => Maybe<ReactNode>;
  onHoveredChanged?: (shape: Maybe<IShape>) => void;
  preserveAspectRatio?: string;
  hoveredShape?: IShape;
}

interface IState {
  overlayShape: IShape;
  overlayShapeHovered: boolean;
  hoveredShape: Maybe<IShape>;
  hoverShapePosition: Maybe<IPosition>;
}

const initialState: IState = {
  overlayShape: {
    id: "overlayShape",
    closed: true,
    points: [
      {
        x: 0,
        y: 0,
      },
    ],
  },
  overlayShapeHovered: false,
  hoveredShape: undefined,
  hoverShapePosition: undefined,
};

export function ShapeViewer({
  image,
  shapes,
  svgStyles,
  invertHover,
  hoverColor,
  renderShapeLink,
  renderShapeOnClickLink,
  renderHovered,
  onHoveredChanged,
  preserveAspectRatio,
  hoveredShape,
  renderShapeOverlayText,
}: IProps) {
  const svgRef = useRef<SVGSVGElement>(null);

  const [state, setState] = useState<IState>(initialState);

  function setHoveredShape(shape: IShape, position: Maybe<IPosition>) {
    if (image) {
      const invertedShape = invertShape(shape, image?.width, image?.height);
      onHoveredChanged && onHoveredChanged(shape);

      setState({
        overlayShape: invertHover ? invertedShape : shape,
        overlayShapeHovered: true,
        hoveredShape: shape,
        hoverShapePosition: position,
      });
    }
  }

  function getPosition(focusedShape: IShape): IPosition | undefined {
    const svgPosition = svgRef.current?.getBoundingClientRect();
    const shapePosition = document.getElementById(focusedShape.id)?.getBoundingClientRect();

    const position = calcShapeCoordinates(svgPosition, shapePosition);
    return position;
  }

  useEffect(() => {
    if (hoveredShape) {
      setHoveredShape(hoveredShape, getPosition(hoveredShape));
    } else {
      setState(initialState);
    }
  }, [hoveredShape]);

  function onMouseEnter(shape: IShape) {
    setHoveredShape(shape, getPosition(shape));
  }

  function onMouseLeave() {
    setState(initialState);
    onHoveredChanged && onHoveredChanged(undefined);
  }

  return (
    <div style={{ position: "relative", height: "100%", width: "100%" }}>
      <svg
        viewBox={`0 0 ${image?.width} ${image?.height}`}
        style={svgStyles}
        className={classes.svg}
        preserveAspectRatio={preserveAspectRatio ?? "none"}
        version="1.1"
        ref={svgRef}
        id={SVG_ID}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <image
          xlinkHref={image?.url}
          className={classes.image}
          x="0"
          y="0"
          width={image?.width}
          height={image?.height}
        ></image>

        <Path
          shape={state.overlayShape}
          fill={hoverColor}
          strokeWidth={0}
          className={classNames(classes.overlay, {
            [classes.overlayActive]: true,
          })}
        />

        {shapes?.map((shape) => {
          const text = renderShapeOverlayText?.(shape);

          if (text) {
            return (
              <Path
                key={shape.id}
                shape={shape}
                fill="rgba(235, 247, 250, 0.4)"
                strokeWidth={0}
                text={text}
                className={classNames(classes.overlay, {
                  [classes.overlayActive]: true,
                })}
              />
            );
          }

          return (
            <ExtendedLink
              key={shape.id}
              to={renderShapeLink(shape)}
              onClick={renderShapeOnClickLink
                ? () => {renderShapeOnClickLink(shape)}
                : undefined
              }
              >
              <Path
                id={shape.id}
                shape={shape}
                fill="transparent"
                strokeWidth={0}
                onMouseEnter={() => onMouseEnter(shape)}
                onMouseLeave={onMouseLeave}
              />
            </ExtendedLink>
          );
        })}
      </svg>
      {renderHovered && state.hoveredShape && state.hoverShapePosition && (
        <div
          style={{
            position: "absolute",
            top: state.hoverShapePosition.top,
            left: state.hoverShapePosition.left,
            right: state.hoverShapePosition.right,
            bottom: state.hoverShapePosition.bottom,
          }}
        >
          {renderHovered(state.hoveredShape)}
        </div>
      )}
    </div>
  );
}

import React, { SVGAttributes, Ref, forwardRef } from "react"
import { IShape, ICoordinate } from "../entites"
import { buildPath } from "./utils"

interface IProps extends SVGAttributes<SVGPathElement> {
  shape: IShape
  fill?: string
  onSelected?: (shape: IShape) => void
  text?: string[]
}

function getMinY(coo: ICoordinate[]): number {
  return coo.reduce((min, p) => (p.y < min ? p.y : min), coo[0].y)
}

function getMaxY(coo: ICoordinate[]): number {
  return coo.reduce((max, p) => (p.y > max ? p.y : max), coo[0].y)
}

function getMinX(coo: ICoordinate[]): number {
  return coo.reduce((min, p) => (p.x < min ? p.x : min), coo[0].x)
}

function getMaxX(coo: ICoordinate[]): number {
  return coo.reduce((max, p) => (p.x > max ? p.x : max), coo[0].x)
}

function getWidth(coo: ICoordinate[]): number {
  return getMaxX(coo) - getMinX(coo)
}

function getHeight(coo: ICoordinate[]): number {
  return getMaxY(coo) - getMinY(coo)
}

export const Path = forwardRef<SVGPathElement, IProps>((props: IProps, ref: Ref<SVGPathElement>) => {
  const { shape, fill, onSelected, onClick, text, ...rest } = props

  const left = getMinX(shape.points)
  const top = getMinY(shape.points)
  // const right = getMaxX(shape.points) 
  // const bottom = getMaxY(shape.points) 
  const width = getWidth(shape.points)
  const height = getHeight(shape.points)

  // const textWidth = 200 
  // const textHeight = 20 

  return (
    <>
      <path
        ref={ref}
        d={buildPath(shape)}
        fill={fill}
        stroke="gray"
        strokeWidth={1}
        onClick={(e: React.MouseEvent<SVGPathElement, MouseEvent>): void => {
          if (onSelected) {
            onSelected(shape)
          } else {
            onClick && onClick(e)
          }
        }}
        style={{
          position: "relative",
        }}
        {...rest}
      ></path>

      {text && (
        <g transform={`translate(${left + width / 2}, ${top + height / 2})`}>
          <text style={{ fontSize: 150 }} fill="rgb(182, 11, 11)">
            {text.map((t, i) => (
              <tspan
                key={t}
                x="0"
                y={i * 200}
                textAnchor="middle"
                alignmentBaseline="middle"
                fontWeight={800}
                fontFamily="SF-Pro"
              >
                {t}
              </tspan>
            ))}
          </text>
        </g>
      )}
    </>
  )
})

Path.displayName = "Path" 

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAppReduxState } from "../../../../../redux/store";
import { IAsyncData } from "../../../../../core/models";
import { apartmentRedux } from "../state/state";
import { IApartment } from "../../data/entities";
import { isSuccess } from "../../../../../core/redux";
import { BackImage } from "../../../../../components/back_image/back_image";
import { Page } from "../../../../../components/page/page";
import styled from "styled-components";
import { AreaItem } from "../components/area_item";
// import { Copyright } from "../../../../../components/copyright/copyright";
import { InteriorDesignModal } from "../components/interior_design_modal";
// import { AboutRepairModal } from "../components/about_repair_modal";
import { ApartmentTopNav } from "../components/apartment_top_nav";
import { ApartmentInfoPanel } from "../components/apartment_info_panel";
import { ApartmentActions } from "../components/apartment_actions";
// import { BackLink } from "../../../../components/back_link";
import { ROUTES } from "../../../../../routes";
import { Compass } from "../../../../../components/compass";
import arrow_right from "../../../../../../assets/img/utils/arrow_right.svg";
import { useTranslation } from "react-i18next";

const BREAK_POINT_MD = 700;
const BREAK_POINT_SM = 700;

const Flex = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-gap: 30px;

  @media (max-width: ${BREAK_POINT_MD}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${BREAK_POINT_SM}px) {
    grid-template-columns: 1fr;
  }
`;

const Right = styled.div`
  margin-left: 90px;
  margin-top: 30px;
  color: white;

  @media (max-width: ${BREAK_POINT_MD}px) {
    order: 2;
  }

  @media (max-width: ${BREAK_POINT_SM}px) {
    margin-left: 0;
    justify-self: center;
  }
`;

const Center = styled.div`
  // display: flex;
  flex: 1;
  justify-content: center;
  margin: 0 auto;
  padding: 1rem 1rem 0 1rem;
  align-self: start;

  @media (max-width: ${BREAK_POINT_MD}px) {
    order: 1;
  }
`;

const Left = styled.div`
  // display: flex;
  // justify-self: end;
  padding: 10px;
  align-self: start;
  background: #8ca2c2;
  height: 100%;

  @media (max-width: ${BREAK_POINT_MD}px) {
    order: 3;
    grid-column: 1 / span 2;
    justify-self: center;
  }

  @media (max-width: ${BREAK_POINT_SM}px) {
    grid-column: unset;
  }
`;

// const BottomRow = styled.div`
//   margin-top: 30px;
// `;

const Img = styled.img`
  display: block;
  max-height: 80vh;
  width: auto;
  height: auto;
`;

const StyledApartmentInfoPanel = styled(ApartmentInfoPanel)`
  @media (max-width: ${BREAK_POINT_MD}px) {
    border-radius: 23px;
  }
`;

const CompassWrapper = styled.div`
  width: 200px;
  margin-top: 30px;
`;

export const ApartmentDetailPage: React.FC<any> = (props: any) => {
  const { apartmentId, sectorId, floorId, onPageChange } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation()

  const [isInteriorDesignModalOpen, setIsInteriorDesignModalOpen] =
    useState(false);
  const [isAboutRepairModalOpen, setIsAboutRepairModalOpen] = useState(false);

  function openInteriorDesignModal() {
    setIsInteriorDesignModalOpen(true);
  }

  function closeInteriorDesignModal() {
    setIsInteriorDesignModalOpen(false);
  }

  function openAboutRepairModal() {
    setIsAboutRepairModalOpen(true);
  }

  function closeAboutRepairModal() {
    setIsAboutRepairModalOpen(false);
  }

  function onClickBack() {
    if (sectorId && floorId) {
      onPageChange({
        route: ROUTES.floor,
        param1: sectorId,
        param2: floorId,
      });
    } else {
      onPageChange({});
    }
  }

  useEffect(() => {
    dispatch(apartmentRedux.actions.getDetail(apartmentId));
  }, [dispatch, apartmentId]);
  const apartmentDetailBranch = useSelector<
    IAppReduxState,
    IAsyncData<IApartment>
  >((state) => state.apartment.details);

  return (
    <div>
      <BackImage />
      <Page>
        <>
          {isSuccess(apartmentDetailBranch) && (
            <>
              <Flex>
                <Left>
                  <ApartmentTopNav
                    apartmentDetailBranch={apartmentDetailBranch}
                    onPageChange={onPageChange}
                  />
                  <StyledApartmentInfoPanel
                    apartmentDetailBranch={apartmentDetailBranch}
                  />
                </Left>

                <Center>
                  <Img src={apartmentDetailBranch.data?.image.url} className="w-100" />
                  <div className="btn-container d-flex justify-content-start mt-2">
                    <a className="more-info-btn p-2" onClick={onClickBack}>
                      <img
                        className=""
                        src={arrow_right}
                        style={{ transform: "rotate(-180deg)" }}
                        alt=""
                      />
                      <small>{t('common:go_back')}</small>
                    </a>
                  </div>
                </Center>

                <Right>
                  {apartmentDetailBranch.data?.areas.map((area) => (
                    <AreaItem key={area.id} area={area} />
                  ))}

                  <CompassWrapper>
                    <Compass compass={apartmentDetailBranch.data?.compass} />
                  </CompassWrapper>
                </Right>
              </Flex>
            </>
          )}

          {/* <ApartmentActions
            apartmentDetailBranch={apartmentDetailBranch}
            openInteriorDesignModal={openInteriorDesignModal}
            openAboutRepairModal={openAboutRepairModal}
          /> */}

          <InteriorDesignModal
            show={isInteriorDesignModalOpen}
            onHide={closeInteriorDesignModal}
          />
          {/* <AboutRepairModal show={isAboutRepairModalOpen} onHide={closeAboutRepairModal} /> */}

          {/* <BottomRow>
            <Copyright />
          </BottomRow> */}
        </>
      </Page>

      {/* <BackLink
        href={`${ROUTES.floor}/${apartmentDetailBranch.data?.sectorId}/${apartmentDetailBranch.data?.floorId}`}
      /> */}
    </div>
  );
};

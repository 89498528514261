import { Link, useParams } from 'react-router-dom'
import arrow_right from "../../../../assets/img/utils/arrow_right.svg"
import { useEffect, useState } from 'react'
import { apiClient } from '../../../core/api_client'
import DOMPurify from "dompurify"
import GmsSharedBanner from './GmsSharedBanner'
import { useTranslation } from 'react-i18next'
import l from '../../../core/localize'
import Breadcrumb from '../../../components/Breadcrumb'

const Announcement = () => {
    const sanitize = DOMPurify.sanitize
    const [announcement, setAnnouncement] = useState({})
    const id = useParams().id
    const { t, i18n } = useTranslation()
    const currentLang = i18n.language.slice(0, 2)

    useEffect(() => {
        apiClient.get('/announcements/' + id).then(res => setAnnouncement(res.data))
    }, [id])

    const breadcrumb = [
        {
            id: 1,
            link: '/elektron-xidmetler/gms',
            style: "pe-none",
            title: t('common:e_services'),
            isActive: false
        },
        {
            id: 2,
            link: '/elektron-xidmetler/gms',
            title: t('gms:gms'),
            isActive: false
        },
        {
            id: 3,
            link: 'https://kommunal.mida.gov.az/search',
            title: t('common:kommunal'),
            isActive: true
        }
    ]

    return (
        <div className='block-bg1 pt-md-3 pt-2 pb-5'>
            <Breadcrumb data={breadcrumb} />
            <GmsSharedBanner />
            <div className="announcement-page">
                <div className="container">
                    <h2 className='my-5'>{l(announcement, "name", currentLang)}</h2>
                    <div dangerouslySetInnerHTML={{ __html: sanitize(l(announcement, "content", currentLang)) }}></div>
                </div>
                <div className="container">
                    <Link to="/elektron-xidmetler/satis-elanlari" className="more-info-btn py-2 px-3 mt-3" style={{ width: "max-content" }}>
                        <img
                            className=""
                            src={arrow_right}
                            style={{ transform: "rotate(-180deg)" }}
                            alt=""
                        />
                        <small>{t('common:all_announcements')}</small>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Announcement 

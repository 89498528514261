import React, { useState } from 'react'
import Modal, { ModalProps } from "react-bootstrap/Modal"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { apiClient } from '../core/api_client'
import parse from 'html-react-parser'
import search_icon from '../../assets/img/utils/search-white.svg'
import { Link } from 'react-router-dom'

const ModalHeader = styled(Modal.Header)`
  background-color: #223A5E;
  color: white;
  border: none;
  display: flex;
  align-items: center;
`

const ModalContent = styled(Modal.Body)`
  color: #424650;
  padding: 0;
`

interface IProps {
    children: React.ReactNode
    className: string
}

interface Result {
    name: string
    content: string
    entityEnum: string
    id: any
}

export const SearchModal: React.FC<IProps> = (props: IProps) => {
    const [searchQuery, setSearchQuery] = useState('')
    const [results, setResults] = useState<Result[]>()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const { t, i18n } = useTranslation()
    const currentLang = i18n.language.slice(0, 2)
    const { children } = props

    const links = [
        {
            entity: "ANNOUNCEMENT",
            path: '/elektron-xidmetler/satis-elanlari/'
        },
        {
            entity: "NEWS",
            path: '/xeberler-ve-media/bizim-xeberler/'
        },
        {
            entity: "MULTIMEDIA_GALLERY",
            path: ''
        }
    ]

    function openModal() {
        setIsModalOpen(true)
    }

    function closeModal() {
        setIsModalOpen(false)
    }

    const handleInputChange = (event: any) => {
        setSearchQuery(event.target.value)
    }

    const handleFormSubmit = (event: any) => {
        event.preventDefault()
        setIsLoading(true)
        apiClient.get(`/search?text=${searchQuery}&lang=${currentLang.toString()}`)
            .then(response => {
                setResults(response.data)
                setIsLoading(false)
                // console.log(response.data)
            })
        // console.log(currentLang)
    }

    const assignLink = (entity: string, id: number): string => {
        const matchedLink = links.find((item) => item.entity === entity)

        if (matchedLink) {
            return matchedLink.path + id
        }

        // Return a default value or handle the case when no match is found
        return ''
    }

    return (
        <>
            <div onClick={openModal} className={"d-flex " + props.className} style={{
                marginBottom: '0.4rem',
                padding: '0.2rem 1rem'
            }}>
                {children}
            </div>
            <Modal
                className='search-modal'
                show={isModalOpen}
                onHide={closeModal}
            >
                <ModalHeader closeButton closeVariant='white'>
                    <Modal.Title>Axtarış</Modal.Title>
                </ModalHeader>
                <div className='modal-content'>
                    <form onSubmit={handleFormSubmit} className='px-4 mt-3 mb-2 d-flex flex-wrap flex-md-nowrap justify-content-center justify-content-md-start'>
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={handleInputChange}
                            placeholder="Axtarış..."
                            style={{
                                padding: '1rem',
                                width: '80%',
                                borderRadius: '50px',
                                border: '0.5px solid',
                                outline: 'none'
                            }}
                        />
                        <button type="submit" className='btn-search ms-4 px-lg-4 py-lg-0 px-5 py-3 mt-4 mt-lg-0'><img className='me-2' src={search_icon} />Axtar</button>
                    </form>
                    {isLoading ? (
                        <div className='d-flex justify-content-center align-items-center h-100'>
                            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                        </div>
                    ) : (
                        results && <div style={{ overflowY: 'auto', height: '70vh' }}>
                            <h3 className='t-color3 text-center my-4 mt-lg-5 mb-lg-3'>Axtarış nəticələri</h3>
                            <div className='px-2'>
                                {results.map(item =>
                                    <Link to={() => assignLink(item.entityEnum, item.id)} onClick={closeModal} className="search-result list-group-item card my-2 p-3" key={results.indexOf(item)}>
                                        <div className="card-body">
                                            <h6 className='mb-0'>{parse(item.name)}</h6>
                                        </div>
                                    </Link>
                                )}
                            </div>
                        </div>)}
                </div>
            </Modal>
        </>
    )
}


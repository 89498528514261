import React from "react";
import { IProjectSector, EProjectSectorStatus } from "../../data/entities";
import styled from "styled-components";

const Wrapper = styled.div`
  border-radius: 5px;
  background-color: #d7d0ca;
  overflow: hidden;
`;

const Title = styled.div`
  background-color: #223A5E;
  padding: 10px;
  color: white;
  text-align: center;
  font-weight: 800;
  font-size: 1rem;
`;

const Content = styled.div`
  padding: 10px 24px;
  color: white;
  background-color: #8CA2C2;
`;

const FloorCount = styled.h5`
  margin: 0;
  text-align: center;
  padding-bottom: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 800;
  border-bottom: 1px solid #424650;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const Text = styled.p`
  font-size: 14px;
  line-height: 14px;
  width: 100px;
  margin: 0;
`;

const Seperator = styled.p`
  margin: 0 0.5rem;
`;

const Number = styled.p`
  width: 25px;
  // text-align: right;
  font-size: 14px;
  line-height: 14px;
  margin: 0;
`;

const InactiveText = styled.p`
  text-align: center;
  margin-bottom: 0;
  color: #F10C0C;
`;

interface Props {
  sector: IProjectSector;
}

export function SectorInfo({ sector }: Props) {
  return (
    <Wrapper>
      <Title>Giriş {sector.name}</Title>
      <Content>
        {sector.status === EProjectSectorStatus.Active ? (
          <>
            <FloorCount>{sector.floorCount} Mərtəbəli</FloorCount>
            <Item>
              <Text>1 otaqlı mənzil</Text>
              <Seperator>-</Seperator>
              <Number>{sector.oneRoomCount}</Number>
            </Item>
            <Item>
              <Text>2 otaqlı mənzil</Text>
              <Seperator>-</Seperator>
              <Number>{sector.twoRoomCount}</Number>
            </Item>
            <Item>
              <Text>3 otaqlı mənzil</Text>
              <Seperator>-</Seperator>
              <Number>{sector.threeRoomCount}</Number>
            </Item>
            <Item>
              <Text>4 otaqlı mənzil</Text>
              <Seperator>-</Seperator>
              <Number>{sector.fourRoomCount}</Number>
            </Item>
          </>
        ) : sector.status === EProjectSectorStatus.Inactive ? (
          <InactiveText>
            Mənzillər barədə məlumatlar <br />
            tezliklə aktivləşdiriləcək
          </InactiveText>
        ) : sector.status === EProjectSectorStatus.Investor ? (
          <InactiveText>
            İnvestor Mənzilləri
          </InactiveText>
        ) : (
          <InactiveText>Bütün mənzillər satılmışdır</InactiveText>
        )}
      </Content>
    </Wrapper>
  );
}

import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../../../routes";
import { IAsyncData } from "../../../../../core/models";
import { IFloor } from "../../data/entities";
// import { theme } from "../../../../../../theme/variables";

const Title = styled.h4`
  text-align: center;
  margin: 10px 0;

  @media screen and (max-width: 1300px) {
    margin-bottom: 20px;
  }
`;

const FloorNumber = styled.span`
  font-size: 22px;
  font-weight: 700;
  color: white;
`;

const FloorControl = styled.a`
  cursor: pointer;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.5);
  transition: 0.2s color ease;

  :hover,
  :focus {
    color: white;
  }
`;

interface IProps {
  floorDetailBranch: IAsyncData<IFloor>;
  sectorId: string;
  onPageChange: (params: any) => void;
}

export const FloorTopNav: React.FC<IProps> = ({ floorDetailBranch, sectorId, onPageChange }: IProps) => {
  const prevFloorId = floorDetailBranch.data?.prevFloorId;
  const nextFloorId = floorDetailBranch.data?.nextFloorId;

  return (
    <Title>
      {prevFloorId && (
        <FloorControl
          onClick={() => onPageChange({
            route: ROUTES.floor,
            param1: sectorId,
            param2: prevFloorId,
          })}>
          {`Mərtəbə ${floorDetailBranch.data?.prevFloorNumber} < `}
        </FloorControl>
      )}

      <FloorNumber>{` Mərtəbə ${floorDetailBranch.data?.number} `}</FloorNumber>

      {nextFloorId && (
        <FloorControl
          onClick={() => onPageChange({
            route: ROUTES.floor,
            param1: sectorId,
            param2: nextFloorId,
          })}>
          {` > Mərtəbə ${floorDetailBranch.data?.nextFloorNumber}`}
        </FloorControl>
      )}
    </Title>
  );
};

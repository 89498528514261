import Home from "./modules/home/Home";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import { useHistory } from "react-router-dom";

import Layout from "./components/layout/Layout";
import About1 from "./modules/agentlik/About1";
import About2 from "./modules/agentlik/About2";
import About3 from "./modules/agentlik/About3";
import Cooperation from "./modules/agentlik/Cooperation";
import MidaMMC from "./modules/agentlik/MidaMMC";
import Tender from "./modules/agentlik/Tender";
import Management from "./modules/agentlik/Management";
import Management2 from "./modules/agentlik/Management2";
import Audit2 from "./modules/agentlik/Audit2";
import Constitution1 from "./modules/legislation/Constitution1";
import Constitution2 from "./modules/legislation/Constitution2";
import Constitution3 from "./modules/legislation/Constitution3";
import Constitution4 from "./modules/legislation/Constitution4";
import Constitution5 from "./modules/legislation/Constitution5";
import Confidential from "./modules/legislation/Confidential";
import Contact1 from "./modules/contact/Contact1";
import Contact2 from "./modules/contact/Contact2";
import Contact3 from "./modules/contact/Contact3";
import ProjectPage from "./modules/projects/ui/ProjectPage";
// import ProjectPageInProgress from "./modules/projects/ui/ProjectPageInProgress";
import Multimedia from "./modules/multimedia/Multimedia";
import Projects from "./modules/projects/ui/Projects";
import PhotoGallery from "./modules/multimedia/PhotoGallery";
import VideoGallery from "./modules/multimedia/VideoGallery";
import ScrollToTop from "./components/utils/scrollToTop";
import News from "./modules/news/ui/News";
import NewsPage from "./modules/news/ui/NewsPage";
import { initI18n } from "./i18n";
import GMS from "./modules/elektron xidmetler/GMS/GMS";
import AllAnnouncements from "./modules/elektron xidmetler/GMS/AllAnnouncements";
import Announcement from "./modules/elektron xidmetler/GMS/Announcement";
// import Kommunal from "./modules/elektron xidmetler/kommunal/Kommunal";
// import International from "./modules/elektron xidmetler/GMS/International";
import SatishHesabatlari from "./modules/agentlik/SatishHesabatlari";
import CooperationPage from "./modules/agentlik/CooperationPage";
import Rules from "./modules/elektron xidmetler/GMS/Rules";
import SatishHesabatPage from "./modules/agentlik/SatishHesabatPage";
import SatishUzreHesabatlar from "./modules/agentlik/SatishUzreHesabatlar";
import SatishHesLayiheUzre from "./modules/agentlik/SatishHesLayiheUzre";
// import SellsHouses from "./modules/agentlik/LayihelerRegemlerle";
import LayihelerRegemlerle from "./modules/agentlik/LayihelerRegemlerle";
import MenzilSechimiStat from "./modules/agentlik/MenzilSechimiStat";
import MenzilSechimiStatPage from "./modules/agentlik/MenzilSechimiStatPage";
import RulesList from "./modules/elektron xidmetler/GMS/RulesList";
import Map from "./components/projects_map/Map";


initI18n();

// type RouteElement = {
//   key: string,
//   path: string,
//   exact?: boolean,
//   Component: React.FC,
// }

// const routes: RouteElement[] = [
//   {
//     key: 'home',
//     exact: true,
//     path: '/',
//     Component: Home,
//   }
// ]

export const App: React.FC = () => {
  // Disable right-click
  // document.addEventListener('contextmenu', (e) => e.preventDefault());

  // function ctrlShiftKey(e: any, keyCode: any) {
  //   return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
  // }

  // document.onkeydown = (e) => {
  //   // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
  //   if (
  //     e.keyCode === 123 ||
  //     ctrlShiftKey(e, 'I') ||
  //     ctrlShiftKey(e, 'J') ||
  //     ctrlShiftKey(e, 'C') ||
  //     (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))
  //   )
  //     return false;
  // };

  const { t } = useTranslation();

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Switch>
          <Route exact path="/">
            <Layout>
              <Home />
            </Layout>
          </Route>
          <Route exact path="/agentlik/haqqimizda">
            <Layout>
              <About1 />
            </Layout>
          </Route>
          <Route exact path="/agentlik/nizamname">
            <Layout>
              <About2 />
            </Layout>
          </Route>
          <Route exact path="/agentlik/mida-mmc">
            <Layout>
              <MidaMMC />
            </Layout>
          </Route>
          <Route exact path="/agentlik/mida-mmc/elanlar/:id">
            <Layout>
              <Tender />
            </Layout>
          </Route>
          <Route exact path="/agentlik/beynelxalq-emekdasliq">
            <Layout>
              <Cooperation />
            </Layout>
          </Route>
          <Route exact path="/agentlik/beynelxalq-emekdasliq/:coopId">
            <Layout>
              <CooperationPage />
            </Layout>
          </Route>
          <Route exact path="/agentlik/hesabatlar/maliyye">
            <Layout>
              <Audit2 />
            </Layout>
          </Route>
          <Route exact path="/agentlik/hesabatlar/satis">
            <Layout>
              <SatishHesabatlari />
            </Layout>
          </Route>
          <Route exact path="/agentlik/hesabatlar">
            <Layout>
              <About3 />
            </Layout>
          </Route>
          <Route exact path="/agentlik/hesabatlar/satis/satis-uzre">
            <Layout>
              <SatishUzreHesabatlar />
            </Layout>
          </Route>
          <Route exact path="/agentlik/hesabatlar/satis/layihe-uzre">
            <Layout>
              <SatishHesLayiheUzre />
            </Layout>
          </Route>
          <Route exact path="/agentlik/hesabatlar/satis/menzil-secimi-statistikasi">
            <Layout>
              <MenzilSechimiStat />
            </Layout>
          </Route>
          <Route exact path="/agentlik/hesabatlar/satis/menzil-secimi-statistikasi/:statId">
            <Layout>
              <MenzilSechimiStatPage />
            </Layout>
          </Route>
          <Route exact path="/agentlik/hesabatlar/lahiyelerin-statistikasi">
            <Layout>
              <LayihelerRegemlerle />
            </Layout>
          </Route>
          <Route exact path="/agentlik/hesabatlar/satis/:sellId">
            <Layout>
              <SatishHesabatPage />
            </Layout>
          </Route>
          <Route exact path="/agentlik/rehberlik">
            <Layout>
              <Management />
            </Layout>
          </Route>
          <Route exact path="/agentlik/rehberlik/etrafli/:managementId">
            <Layout>
              <Management2 />
            </Layout>
          </Route>
          <Route exact path="/xeberler-ve-media/bizim-xeberler">
            <Layout>
              <News />
            </Layout>
          </Route>
          <Route exact path="/xeberler-ve-media/bizim-xeberler/:newsId">
            <Layout>
              <NewsPage />
            </Layout>
          </Route>
          <Route exact path="/xeberler-ve-media/multimedia">
            <Layout>
              <Multimedia />
            </Layout>
          </Route>
          <Route exact path="/xeberler-ve-media/fotogalereya">
            <Layout>
              <PhotoGallery />
            </Layout>
          </Route>
          <Route exact path="/xeberler-ve-media/videogalereya/:id?">
            <Layout>
              <VideoGallery />
            </Layout>
          </Route>
          <Route exact path="/layiheler/:categoryId?">
            <Layout>
              <Projects />
            </Layout>
          </Route>
          <Route exact path="/layihe/:projectId">
            <Layout>
              <ProjectPage />
            </Layout>
          </Route>
          <Route exact path="/qanunvericilik/ar-konstitusiyasi">
            <Layout>
              <Constitution1 />
            </Layout>
          </Route>
          <Route exact path="/qanunvericilik/ar-mecelleri">
            <Layout>
              <Constitution2 />
            </Layout>
          </Route>
          <Route exact path="/qanunvericilik/ar-qanunlari">
            <Layout>
              <Constitution3 />
            </Layout>
          </Route>
          <Route exact path="/qanunvericilik/ar-prezidentinin-fermanlari">
            <Layout>
              <Constitution4 />
            </Layout>
          </Route>
          <Route exact path="/qanunvericilik/strateji-yol-xeriteleri">
            <Layout>
              <Constitution5 />
            </Layout>
          </Route>
          <Route exact path="/qanunvericilik/konfidensialliq">
            <Layout>
              <Confidential />
            </Layout>
          </Route>
          <Route exact path="/elektron-xidmetler/gms">
            <Layout>
              <GMS />
            </Layout>
          </Route>
          <Route exact path="/elektron-xidmetler/gms/gms-kategoriyalar">
            <Layout>
              <Rules />
            </Layout>
          </Route>
          <Route exact path="/elektron-xidmetler/gms/gms-kategoriyalar/siyahi">
            <Layout>
              <RulesList />
            </Layout>
          </Route>
          {/* <Route exact path="/elektron-xidmetler/kommunal-odenis-sistemi">
          <Layout>
            <Kommunal />
          </Layout>
        </Route> */}
          <Route exact path="/elektron-xidmetler/satis-elanlari">
            <Layout>
              <AllAnnouncements />
            </Layout>
          </Route>
          <Route exact path="/elektron-xidmetler/satis-elanlari/:id">
            <Layout>
              <Announcement />
            </Layout>
          </Route>
          {/* <Route exact path="/elektron-xidmetler/beynelxalq-reyler">
          <Layout>
            <International />
          </Layout>
        </Route> */}

          <Route exact path="/elaqe/elaqe-vasiteleri">
            <Layout>
              <Contact1 />
            </Layout>
          </Route>
          <Route exact path="/elaqe/metbuat-xidmeti">
            <Layout>
              <Contact2 />
            </Layout>
          </Route>
          <Route exact path="/elaqe/en-cox-verilen-suallar">
            <Layout>
              <Contact3 />
            </Layout>
          </Route>
          <Route exact path="/test-map">
            <Layout>
              <Map />
            </Layout>
          </Route>
          <Route path="*">
            <Layout>
              <h1 className="text-center t-color5" style={{ padding: "10rem" }}>
                404 - {t('common:404')}
              </h1>
            </Layout>
          </Route>
        </Switch>
      </BrowserRouter >
    </>
  );
};
export default App;
// libraries
import { Link } from "react-router-dom";
import { Pagination, Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// assets
import arrowl_0 from '../../../../assets/img/utils/slider-arrow-l-0.svg';
import arrowr_0 from '../../../../assets/img/utils/slider-arrow-r-0.svg';
import arrowl_1 from '../../../../assets/img/utils/slider-arrow-l-1.svg';
import arrowr_1 from '../../../../assets/img/utils/slider-arrow-r-1.svg';

// styles
import "swiper/css";
import "swiper/css/pagination";
import l from "../../../core/localize";
import { Routing } from "../../../core/routing";
import { apiClient } from "../../../core/api_client";

const Slider = () => {
    const [hover, setHover] = useState(false)
    const [hover2, setHover2] = useState(false)
    const [slider, setSlider] = useState([])
    const { i18n } = useTranslation()
    const currentLang = i18n.language.slice(0, 2)

    useEffect(() => {
        apiClient.get('/sliders', { params: { lang: currentLang } })
            .then(res => {
                setSlider(res.data.sort((a, b) => b.index - a.index));
            })
            .catch(err => console.log(err))
    }, [currentLang])

    if (slider.length === 0) return <></>
    return (
        <div className="slider">
            <div className="swiper-button-prev-n swiper-slide-btn d-md-flex d-none"
                style={{
                    height: '100%',
                    transform: "unset",
                    top: "0",
                    zIndex: 2
                }}
                onMouseEnter={_ => setHover(true)}
                onMouseLeave={_ => setHover(false)}
            >
                <img src={hover ? arrowl_1 : arrowl_0} style={{ height: "100px", margin: "auto", paddingRight: "13.5rem", userSelect: 'none' }} alt="" />
            </div>
            <Swiper
                pagination={{ clickable: true }}
                modules={[Pagination, Autoplay, Navigation]}
                autoplay={{
                    delay: 10000,
                    disableOnInteraction: false,
                }}
                loop={true}
                navigation={{
                    nextEl: '.swiper-button-next-n',
                    prevEl: '.swiper-button-prev-n',
                }}
                className="mySwiper h-100 w-100"
            >
                {slider && slider.map(item =>
                    <SwiperSlide key={item.id}>
                        <Link to={item.link} className="w-100 h-100">
                            <img src={Routing.generateFileRoute(l(item, "file", currentLang))} style={{ objectFit: "cover", objectPosition: 'top' }} className="block-bg1" alt={l(item, "name", currentLang)} />
                            <div className="slider-text position-absolute bottom-0 w-100 py-md-3 py-2">
                                <div className="container">
                                    <small className="hover-text-shift">{l(item, "name", currentLang)}</small>
                                </div>
                            </div>
                        </Link>
                    </SwiperSlide>
                )}
            </Swiper>
            <div className="swiper-button-next-n swiper-slide-btn d-md-flex d-none"
                style={{
                    height: '100%',
                    transform: "unset",
                    top: "0",
                    zIndex: 2
                }}
                onMouseEnter={_ => setHover2(true)}
                onMouseLeave={_ => setHover2(false)}
            >
                <img src={hover2 ? arrowr_1 : arrowr_0} style={{ height: "100px", margin: "auto", paddingLeft: "13.5rem", userSelect: 'none' }} alt="" />
            </div>
        </div>
    );
}

export default Slider;

import axios, { AxiosInstance } from "axios"

export interface ApiClient extends AxiosInstance { }

const currentDomain = window.location.hostname

const prodDomain = "mida.gov.az"
const stagingDomain = "mida.asdfghjkl.gov.az"
const devDomain = "mida-website-front.vercel.app"

const prodApiUrl = "https://api.mida.gov.az"
const stagingApiUrl = "http://api-mida.asdfghjkl.gov.az"
const devApiUrl = "https://api.mida.gov.az"
// const localApiUrl = "https://api.mida.gov.az" 
const localApiUrl = "http://api-mida.asdfghjkl.gov.az"

function getBaseApiUrl(): string {
  if (currentDomain === prodDomain) {
    return prodApiUrl
  } else if (currentDomain === stagingDomain) {
    return stagingApiUrl
  } else if (currentDomain === devDomain) {
    return devApiUrl
  }
  return localApiUrl
}

export const BASE_URL = getBaseApiUrl()

export const apiClient: ApiClient = axios.create({
  baseURL: `${BASE_URL}/api/front`,
  headers: {
    "content-type": "application/json",
  }
}) 

import React, { useEffect, useState } from "react";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import { ModalHeader } from "../../../apartment/ui/components/interior_design_modal";
import { useDispatch, useSelector } from "react-redux";
import { projectRedux } from "../state/state";
import {
  IProjectApartmentFilter,
  IPaginated,
  IProjectApartment,
} from "../../data/entities";
import { IAppReduxState } from "../../../../../redux/store";
import { IAsyncData } from "../../../../../core/models";
import range from "lodash/range";
import Square from "../../../../../components/square";
import styled from "styled-components";
import { Loading } from "../../../../../components/loading";
import { isPending } from "../../../../../core/redux";
import { ROUTES } from "../../../../../routes";
// import Select from "react-select";
import { Pagination } from "../../../../../components/pagination";
import img from "../../../../../../assets/img/utils/search-white.svg";
import { ERepairType } from "../../../apartment/data/entities";

const SubmitButton = styled.button`
  padding: 8px 20px;
  background: rgba(248, 133, 27, 0.44);
  box-shadow: none;
  color: white;
  display: flex;
  align-items: center;
  border-radius: 35px;
  border: none;
  font-weight: 800;
`;

const SubmitButtonWrapper = styled(Col)`
  margin: 1rem 0;
  display: flex;
  justify-content: flex-end;
  align-items: end;
`;

const FormLabel = styled(Form.Label)`
  font-weight: 800;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 19px;
  color: #223a5e;
`;

const Thead = styled.thead`
  background-color: #8ca2c2;
  color: white;
`;

const thBorderRadius = 3;

const Th = styled.th`
  font-size: 18px;
  color: white;
  text-align: center;
  text-transform: uppercase;
  border: none !important;
  padding: 0.75rem 7px !important;
  border-right: 1px solid white;

  :first-child {
    border-radius: ${thBorderRadius}px 0 0 ${thBorderRadius}px;
  }
  :last-child {
    border-radius: 0 ${thBorderRadius}px ${thBorderRadius}px 0;
  }
`;

const Tr = styled.tr`
  border-top: 1px solid #8CA2C2;
  border-bottom: 1px solid #8CA2C2;
  color: #4E565A;


`;

const Tbody = styled.tbody`
  background: white !important;
`;

const Td = styled.td`
  padding: 1rem 7px !important;
  background: white !important;

  :first-child {
    border-radius: ${thBorderRadius}px 0 0 ${thBorderRadius}px;
  }
  :last-child {
    border-radius: 0 ${thBorderRadius}px ${thBorderRadius}px 0;
  }
`;

const ModalContent = styled(Modal.Body)`
  color: #424650;
  padding: 5px 10px 5px 20px;
  position: static;

  @media screen and (max-width: 910px) {
    padding: 30px 10px;
  }
`;

interface IProps extends ModalProps {
  onPageChange: (params: any) => void;
}

interface IState extends IProjectApartmentFilter { }

const initialState: IState = {
  data: {
    sectorTypes: [],
    floor: {
      from: "1",
      to: "2",
    },
    roomCounts: [],
    repairTypes: [
      {
        repairType: ERepairType.one,
      },
    ],
  },
  projectPlanId: 0,
  page: 1,
  limit: 8,
};

const floorRange = (sectorType?: { sectorType: string }[]) => {
  if (sectorType?.length === 1) {
    if (sectorType[0]?.sectorType === "7") {
      return range(1, 8);
    }
  }
  return range(1, 13);
};

export const ApartmentSearchModal: React.FC<IProps> = (props: IProps) => {
  const { onPageChange, projectPlanId } = props;
  const [state, setState] = useState(initialState);
  const dispatch = useDispatch();

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      projectPlanId,
    }));
  }, [projectPlanId]);

  function handleInputChange(name: string, value: any) {
    setState({
      ...state,
      data: {
        ...state.data,
        [name]: value,
      },
    });
  }

  const [tableVisible, setTableVisible] = useState(false);

  function searchApartments() {
    setTableVisible(true);
    dispatch(projectRedux.actions.searchApartments(state));
  }

  // useEffect(() => {
  //   searchApartments();
  // }, [state.page]);

  function handleSubmit(e: any) {
    e.preventDefault();
    e.target.reset();
  }

  const apartmentsBranch = useSelector<
    IAppReduxState,
    IAsyncData<IPaginated<IProjectApartment>>
  >((state) => state.project.apartments);
  const pageCount = apartmentsBranch.data?.pageCount ?? 0;

  return (
    // <Modal dialogClassName="modal-90w" {...props}>
    <div style={{ background: "#8CA2C2", width: "32%" }}>
      <ModalHeader>
        <Modal.Title
          className="w-100"
          style={{ background: "#223A5E", borderRadius: "22px" }}
        >
          Parametrlər üzrə axtarış
        </Modal.Title>
      </ModalHeader>
      <ModalContent>
        <Form id="apt-modal" className="project-check" onSubmit={handleSubmit}>
          <Row className="ps-3 pe-5">
            <Col xs={12} sm={6} md={12} className="p-0 mb-2">
              <Form.Group controlId="sectorType">
                <FormLabel>Bina tipi</FormLabel>
                <div className="d-flex my-1">
                  <input
                    type="checkbox"
                    name="sectorType"
                    id="9f"
                    onChange={() =>
                      handleInputChange("sectorTypes", [
                        ...state.data.sectorTypes,
                        { sectorType: "7" },
                      ])
                    }
                  />
                  <label htmlFor="9f">9 mərtəbəli</label>
                </div>
                <div className="d-flex my-1">
                  <input
                    type="checkbox"
                    name="sectorType"
                    id="12f"
                    onChange={() =>
                      handleInputChange("sectorTypes", [
                        ...state.data.sectorTypes,
                        { sectorType: "7" },
                      ])
                    }
                  />
                  <label htmlFor="12f">12 mərtəbəli</label>
                </div>
              </Form.Group>
              <Form.Group className="my-2">
                <FormLabel>Mərtəbə seçimi</FormLabel>
                <Row>
                  <Col className="ms-auto d-flex align-items-center" xs={7}>
                    <Form.Control
                      className="input-select ms-auto"
                      as="select"
                      name="floorFrom"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChange("floor", {
                          to: state.data.floor.to,
                          from: e.target.value,
                        })
                      }
                      value={state.data.floor.from}
                    >
                      {floorRange(state.data.sectorTypes).map((floor) => (
                        <option value={floor} key={floor}>
                          {floor}
                        </option>
                      ))}
                    </Form.Control>
                    <div
                      style={{
                        width: "55px",
                        height: "1px",
                        fontWeight: 700,
                        backgroundColor: "rgb(34, 58, 94)",
                      }}
                    />
                    <Form.Control
                      className="input-select me-0"
                      as="select"
                      name="floorTo"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChange("floor", {
                          from: state.data.floor.from,
                          to: e.target.value,
                        })
                      }
                      value={state.data.floor.to}
                    >
                      {floorRange(state.data.sectorTypes).map((floor) => (
                        <option value={floor} key={floor}>
                          {floor}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Row>
              </Form.Group>
            </Col>
            <Col xs={12} sm={4} md={12} className="p-0 my-2">
              <Form.Group controlId="roomCount">
                <FormLabel>Otaq sayı</FormLabel>
                <div className="d-flex my-1">
                  <input
                    type="checkbox"
                    name="sectorType"
                    id="studio"
                    onChange={() =>
                      handleInputChange("roomCounts", [
                        ...state.data.roomCounts,
                        { roomCount: "0" },
                      ])
                    }
                  />
                  <label htmlFor="studio">Studio tipli</label>
                </div>
                <div className="d-flex my-1">
                  <input
                    type="checkbox"
                    name="sectorType"
                    id="1r"
                    onChange={() =>
                      handleInputChange("roomCounts", [
                        ...state.data.roomCounts,
                        { roomCount: "1" },
                      ])
                    }
                  />
                  <label htmlFor="1r">1 otaqlı</label>
                </div>
                <div className="d-flex my-1">
                  <input
                    type="checkbox"
                    name="sectorType"
                    id="2r"
                    onChange={() =>
                      handleInputChange("roomCounts", [
                        ...state.data.roomCounts,
                        { roomCount: "2" },
                      ])
                    }
                  />
                  <label htmlFor="2r">2 otaqlı</label>
                </div>
                <div className="d-flex my-1">
                  <input
                    type="checkbox"
                    name="sectorType"
                    id="3r"
                    onChange={() =>
                      handleInputChange("roomCounts", [
                        ...state.data.roomCounts,
                        { roomCount: "3" },
                      ])
                    }
                  />
                  <label htmlFor="3r">3 otaqlı</label>
                </div>
                <div className="d-flex my-1">
                  <input
                    type="checkbox"
                    name="sectorType"
                    id="4r"
                    onChange={() =>
                      handleInputChange("roomCounts", [
                        ...state.data.roomCounts,
                        { roomCount: "4" },
                      ])
                    }
                  />
                  <label htmlFor="4r">4 otaqlı</label>
                </div>
              </Form.Group>
            </Col>
            {/* <Col xs={12} sm={4} md={12} className="p-0 my-2">
              <Form.Group controlId="repairType">
                <FormLabel>Təmir növü</FormLabel>
                <div className="d-flex my-1">
                  <input
                    type="checkbox"
                    name="sectorType"
                    id="1t"
                    onChange={() =>
                      handleInputChange("repairTypes", [
                        ...state.data.repairTypes,
                        { repairType: ERepairType.one },
                      ])
                    }
                  />
                  <label htmlFor="1t">1</label>
                </div>
                <div className="d-flex my-1">
                  <input
                    type="checkbox"
                    name="sectorType"
                    id="2t"
                    onChange={() =>
                      handleInputChange("repairTypes", [
                        ...state.data.repairTypes,
                        { repairType: ERepairType.two },
                      ])
                    }
                  />
                  <label htmlFor="2t">2</label>
                </div>
              </Form.Group>
            </Col> */}
          </Row>
          <SubmitButtonWrapper xs={12} sm={4} md={12}>
            <SubmitButton onClick={searchApartments} type="button">
              <img src={img} className="me-2" alt="" />
              Axtar
            </SubmitButton>
            <button
              type="submit"
              style={{
                border: "none",
                background: "transparent",
                color: "white",
                fontWeight: 700,
                padding: "10px",
              }}
            >
              Yenilə
            </button>
          </SubmitButtonWrapper>
        </Form>

        <Loading
          loading={isPending(apartmentsBranch)}
          style={{
            right: "0",
            zIndex: "10",
            top: "1%",
            width: "70%",
            padding: "0 20px",
          }}
          className={"position-absolute"}
        >
          <Table
            responsive
            hover
            className={"apt-search-modal-table " + (tableVisible ? "d-table" : "d-none")}
          >
            <Thead>
              <tr>
                {/* <Th style={{ minWidth: 80 }}>Bina tipi</Th> */}
                <Th style={{ minWidth: 50 }}>Bina</Th>
                <Th style={{ minWidth: 50 }}>Giriş</Th>
                <Th style={{ minWidth: 75 }}>Mərtəbə</Th>
                <Th style={{ minWidth: 75 }}>Mənzil</Th>
                <Th style={{ minWidth: 80 }}>Otaq sayı</Th>
                {/* <Th style={{ minWidth: 92 }}>Təmir növü</Th> */}
                <Th style={{ minWidth: 95 }}>Sahə</Th>
                <Th style={{ minWidth: 120 }}>Qiymət</Th>
                <Th
                  style={{
                    minWidth: 30,
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => setTableVisible(false)}
                >
                  X
                </Th>
              </tr>
            </Thead>
            <Tbody>
              {apartmentsBranch.data?.list.map((apartment) => (
                <Tr
                  key={apartment.id}
                  onClick={() => onPageChange({
                    route: ROUTES.apartment,
                    param1: apartment.id,
                  })}
                >
                  {/* <Td>{apartment.sectorType} mərtəbəli</Td> */}
                  <Td style={{ textAlign: "center" }}>
                    {apartment.buildingNumber}
                  </Td>
                  <Td style={{ textAlign: "center" }}>{apartment.entrance}</Td>
                  <Td style={{ textAlign: "center" }}>
                    {apartment.floorNumber}
                  </Td>
                  <Td style={{ textAlign: "center" }}>
                    {apartment.apartmentNumber}
                  </Td>
                  <Td style={{ textAlign: "center" }}>{apartment.roomCount}</Td>
                  {/* <Td style={{ textAlign: "center" }}>
                    {apartment.repairType}
                  </Td> */}
                  <Td style={{ textAlign: "center" }}>
                    <Square square={apartment.internalSize} />
                  </Td>
                  <Td style={{ textAlign: "center" }}>{apartment.price} AZN</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          {pageCount !== 0 && tableVisible && (
            <Pagination
              totalPageCount={pageCount}
              currentPage={state.page}
              onPageChanged={(page) => {
                setState({ ...state, page });
              }}
            />
          )}
          {apartmentsBranch.data?.list.length === 0 && tableVisible && (
            <p
              style={{
                textAlign: "center",
                background: "white",
                padding: "1rem 0",
              }}
              className={"py-3 " + tableVisible ? "d-block" : "d-none"}
            >
              Bu şərtlərə uyğun ev tapılmadı.
            </p>
          )}
        </Loading>
      </ModalContent>
      {/* </Modal> */}
    </div>
  );
};

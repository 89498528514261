// libraries 
import { Pagination, Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { useTranslation } from 'react-i18next'

// components
import Breadcrumb from '../../components/Breadcrumb'

// assets
import arrow1 from '../../../assets/img/utils/lil-arrow-l.svg'
import arrow2 from '../../../assets/img/utils/lil-arrow-r.svg'
import img from '../../../assets/img/hesabat_card_img.svg'
import pdf1 from '../../../assets/files/maliyye/2021.pdf'
import pdf2 from '../../../assets/files/maliyye/2020.pdf'
import pdf3 from '../../../assets/files/maliyye/2019.pdf'
import pdf4 from '../../../assets/files/maliyye/2018.pdf'
// import pdf5 from '../../../assets/files/maliyye/2017.pdf'
// import pdf6 from '../../../assets/files/maliyye/2016.pdf'
import pdf7 from '../../../assets/files/maliyye/2022.pdf'

// styles
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"

const Audit2 = () => {
    const { t } = useTranslation()

    const breadcrumb = [
        {
            id: 1,
            link: '/agentlik/haqqimizda',
            title: t('agentlik:agentlik'),
            isActive: false
        },
        {
            id: 2,
            link: '/agentlik/hesabatlar',
            title: t('agentlik:audits'),
            isActive: false
        },
        {
            id: 3,
            link: '/agentlik/hesabatlar/maliyye',
            title: t('agentlik:audits_finance'),
            isActive: true
        }
    ]

    const audit_card = [
        {
            id: 7,
            title: "2022",
            file: pdf7,
            text: "2022-ci il üzrə Mənzil İnşaatı Dövlət Agentliyinin illik maliyyə hesabatlarının audit rəyi",
            // link: "/agentlik/hesabatlar/satis"
        },
        {
            id: 1,
            title: "2021",
            file: pdf1,
            text: "2021-ci il üzrə Mənzil İnşaatı Dövlət Agentliyinin illik maliyyə hesabatlarının audit rəyi",
            // link: "/agentlik/hesabatlar/satis"
        },
        {
            id: 2,
            title: "2020",
            file: pdf2,
            text: "2020-ci il üzrə Mənzil İnşaatı Dövlət Agentliyinin illik maliyyə hesabatlarının audit rəyi",
            // link: "/agentlik/hesabatlar/maliyye"
        },
        {
            id: 3,
            title: "2019",
            file: pdf3,
            text: "2019-cu il üzrə Mənzil İnşaatı Dövlət Agentliyinin illik maliyyə hesabatlarının audit rəyi",
            // link: "/agentlik/hesabatlar/lahiyelerin-statistikasi"
        },
        {
            id: 4,
            title: "2018",
            file: pdf4,
            text: "2018-ci il üzrə Mənzil İnşaatı Dövlət Agentliyinin illik maliyyə hesabatlarının audit rəyi",
            // link: "/agentlik/hesabatlar/exidmet-statistikasi"
        }
    ]

    return (
        <div className="audit-page block-bg1 ">
            <Breadcrumb data={breadcrumb} className="my-0 pt-5" style={{ height: 0 }} />
            <div className='bg-picture2'>
                <div className='container'>
                    <div className='row justify-content-between position-relative pt-60 pb-120 d-none d-lg-flex '>
                        <div className={"swiper-button-prev-n1 position-absolute d-md-flex d-none " + (audit_card.length > 4 ? "" : "d-none d-md-none")} style={{
                            transform: "translate(-150%, -50%)",
                            top: "45%",
                            left: "0",
                            width: "70px",
                            height: "100px"
                        }}>
                            <img src={arrow1} alt="arrow" />
                        </div>
                        <Swiper
                            slidesPerView={4}
                            spaceBetween={20}
                            loop={false}
                            navigation={{
                                nextEl: '.swiper-button-next-n1',
                                prevEl: '.swiper-button-prev-n1',
                            }}
                            modules={[Pagination, Navigation]}
                            id="news-page"
                            className="mySwiper mt-3 mb-3 row gx-2 pb-3 d-none d-md-block">

                            {audit_card.map(item =>
                                <SwiperSlide key={item.id} className='audit-card rounded-4 my-4 cursor px-2'>
                                    <a href={item.file} rel="noreferrer" target="_blank" key={item.id} style={{ width: "24%" }}>
                                        <div className='audit-card_side audit-card_side_back px-2 py-4'>
                                            <div className='pt-60'>
                                                <p className='fw-bold t-color4'>{item.text}</p>
                                                <div className='line mt-2'></div>
                                                {/* <button className='mt-4 px-4 py-1'>{t('common:download')}</button> */}
                                            </div>
                                            <img src={img} className="h-100 mt-3" alt="pdf" />
                                        </div>

                                        <div className='audit-card_side audit-card_side_front pb-120 pt-120 px-60'>
                                            <h2 className='my-auto'>{item.title}</h2>
                                        </div>
                                    </a>
                                </SwiperSlide>
                            )}
                        </Swiper>
                        <div className={"swiper-button-next-n1 position-absolute d-md-flex d-none " + (audit_card.length > 4 ? "" : "d-md-none d-none")} style={{
                            transform: "translate(150%, -50%)",
                            top: "45%",
                            right: "0",
                            width: "70px",
                            height: "100px"
                        }}>
                            <img src={arrow2} alt="" />
                        </div>
                    </div>


                    {/* MOBILE */}
                    <div className='row justify-content-between px-5 d-lg-none d-flex position-relative'>
                        <div className={"swiper-button-prev-n d-flex ms-1 " + (audit_card.length > 2 ? "" : "d-none")} style={{
                            width: "70px",
                            height: "50px"
                        }}>
                            <img src={arrow1} alt="" />
                        </div>
                        <Swiper
                            slidesPerView={2}
                            spaceBetween={10}
                            loop={false}
                            navigation={{
                                nextEl: '.swiper-button-next-n',
                                prevEl: '.swiper-button-prev-n',
                            }}
                            modules={[Pagination, Navigation]}
                            id="news-page"
                            className="mySwiper mt-5 mb-3 row gx-2 pb-3 d-flex">

                            {audit_card.map(item =>
                                <SwiperSlide key={item.id} className='audit-card rounded-4 my-4 cursor px-2' style={{ width: '48%' }}>
                                    <a href={item.file} rel="noreferrer" target="_blank" key={item.id} style={{ width: "22%" }}>
                                        <div className='audit-card_side audit-card_side_back px-2 py-5'>
                                            <div>
                                                <p className='fw-bold t-color4'>{item.text}</p>
                                                <div className='line mt-2'></div>
                                                {/* <button className='mt-4 px-4 py-1'>Yüklə</button> */}
                                            </div>
                                            <img src={img} className="mt-3" alt="pdf" />
                                        </div>
                                        <div className='audit-card_side audit-card_side_front px-5'>
                                            <h2 className='my-auto'>{item.title}</h2>
                                        </div>
                                    </a>
                                </SwiperSlide>
                            )}
                        </Swiper>
                        <div className={"swiper-button-next-n d-flex ps-4 pe-5 " + (audit_card.length > 2 ? "" : "d-none")} style={{
                            width: "70px",
                            height: "50px",
                            right: 0
                        }}>
                            <img src={arrow2} alt="" />
                        </div>
                    </div>
                    {/* MOBILE */}
                </div>
            </div>
        </div>
    )
}

export default Audit2 

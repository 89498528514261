import React from "react"
import classes from "./info_panel.module.scss"
import classnames from "classnames"

interface IProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  title?: string
  children?: React.ReactNode
}

export const InfoPanel: React.FC<IProps> = ({ title, children, className, ...rest }: IProps) => {
  return (
    <div className={classnames(classes.info_panel, className)} {...rest}>
      {title && (<div className={classes.info_panel_title}>
        <h5>{title}</h5>
      </div>)}
      <div className={classes.info_panel_description}>{children}</div>
    </div>
  )
} 

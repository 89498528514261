import styled from "styled-components"

export interface IProps {
  square: string | number | undefined
}

const Text = styled.span`
  margin: 0;
`

export default function Square({ square }: IProps) {
  return (
    <Text>
      {` ${square}`} m<sup>2</sup>
    </Text>
  )
}

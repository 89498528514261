import { FormikHelpers } from "formik"
import { ELanguage } from "../i18n"

export type Maybe<T> = T | undefined | null

export function isNullish<T>(data: Maybe<T>): data is null | undefined {
  return data === null || data === undefined
}

export enum EProcessStatus {
  CANCELED = "CANCELED",
  ERROR = "ERROR",
  IDLE = "IDLE",
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
}

export interface IError {
  message: string
}

export interface IAsyncData<T> {
  data: Maybe<T>
  error: Maybe<IError>
  status: EProcessStatus
  timestamp?: number
}

export interface IFormProps<T> {
  initialValues: Maybe<T>
  onSubmit: (values: T, form: FormikHelpers<T>) => void
  branch: IAsyncData<void>
  submitTitle: string
}

export interface ICRUDRepo<T, TForm, TListPrams = {}, TDetailParams = {}> {
  getList: (filter: TListPrams) => Promise<T[]>
  getDetails: (id: string, filter: TDetailParams) => Promise<T>
  create: (form: TForm) => Promise<void>
  delete: (id: string) => Promise<void>
  edit: (id: string, form: TForm) => Promise<void>
}

export interface IDateRage {
  fromDate: Date
  toDate: Date
}

export interface IMultiLang {
  [ELanguage.az]: string
  [ELanguage.en]: string
  [ELanguage.ru]: string
}

export function multiLangFromJson(data: any, key: string): IMultiLang {
  return {
    [ELanguage.az]: data[`${key}_az`],
    [ELanguage.en]: data[`${key}_en`],
    [ELanguage.ru]: data[`${key}_ru`],
  }
}

import React from "react";
import groupBy from "lodash/groupBy";
import { IFloorApartment, EApartmentStatus } from "../../data/entities";
import { Link } from "react-router-dom";
import { Routing } from "../../../../../core/routing";
import { ROUTES } from "../../../../../routes";
import sortBy from "lodash/sortBy";
import styled from "styled-components";
import { Maybe } from "../../../../../core/models";

const FloorNumber = styled.p`
  margin-bottom: 7px;
  font-weight: 700;
  font-size: 20px;
  text-align: center !important;
  color: #223A5E;
  text-transform: uppercase;
`;

const Item = styled.a<{ disabled: boolean }>`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  color: white;
  font-weight: 700;
  font-size: 1.1rem;
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  border-bottom: 1px solid transparent;
  margin-bottom: 5px;
  line-height: 1;
  padding: 0.2rem 0.6rem;

  :hover,
  :focus,
  &.hovered {
    background: #223a5e;
    border-radius: 22px;
  }
`;

const Entrance = styled.div`
  margin-bottom: 20px;

  :last-child {
    margin-bottom: 0;
  }
`;

const ApartmentNumber = styled.span`
  width: 70px;
  margin-right: 5px;
  text-align: left;
  white-space: nowrap;
`;

const RoomCount = styled.span`
  width: 80px;
  margin-right: 5px;
  text-align: left;
  white-space: nowrap;
`;

const RepairType = styled.span`
  width: 100px;
  text-align: right;
  white-space: nowrap;
`;

function roomCountToString(roomCount: number) {
  switch (roomCount) {
    case 1:
      return "birotaqlı";
    case 2:
      return "ikiotaqlı";
    case 3:
      return "üçotaqlı";
    case 4:
      return "dördotaqlı";
    default:
      return "birotaqlı";
  }
}

interface IProps {
  apartments: IFloorApartment[];
  hoveredApartment: Maybe<IFloorApartment>;
  onApartmentHovered: (apartment: Maybe<IFloorApartment>) => void;
  onPageChange: (params: any) => void;
}

export const ApartmentList: React.FC<IProps> = ({
  apartments,
  onApartmentHovered,
  hoveredApartment,
  onPageChange
}: IProps) => {
  const catagorized = groupBy(apartments, "etrance");

  return (
    <>
      {Object.keys(catagorized).map((entrance) => {
        const catagorizedApartments = catagorized[entrance];
        const sortedApartments = sortBy(
          catagorizedApartments,
          "apartmentNumber"
        );

        return (
          <Entrance key={entrance}>
            <FloorNumber>Giriş {entrance}</FloorNumber>

            {sortedApartments.map((apartment: IFloorApartment) => (
              <Item
                key={apartment.id}
                onClick={() => onPageChange({
                  route: ROUTES.apartment,
                  param1: apartment.id,
                })}
                onMouseOver={() => {
                  onApartmentHovered(apartment);
                }}
                onMouseLeave={() => {
                  onApartmentHovered(undefined);
                }}
                disabled={apartment.status !== EApartmentStatus.AVAILABLE}
                className={
                  apartment.id === hoveredApartment?.id ? "hovered" : ""
                }
              >
                <ApartmentNumber>
                  Mənzil {apartment.apartmentNumber}
                </ApartmentNumber>
                <RoomCount>{roomCountToString(apartment.roomCount)}</RoomCount>
                {/* <RepairType>Təmir növü {apartment.repairType}</RepairType> */}
              </Item>
            ))}
          </Entrance>
        );
      })}
    </>
  );
};

import React from "react";
import { ISectorEtrance } from "../../data/entities";
import styled from "styled-components";

const Item = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 1rem 0;
  color: white;
`;

const Text = styled.p`
  font-size: 20px;
  font-weight: 700;
  // width: 100px;
  margin: 0;
`;

const Seperator = styled.p`
  // text-align: center;
  // flex: 1;
  margin: 0 0.5rem;
`;

const Number = styled.p`
  width: 25px;
  font-size: 20px;
  font-weight: 700;
  // text-align: right;
  margin: 0;
`;

interface IProps {
  etrance: ISectorEtrance;
}

export const EtranceInfo: React.FC<IProps> = ({ etrance }: IProps) => {
  return (
    <div key={etrance.name}>
      <Item>
        <Text>1 otaqlı mənzil</Text>
        <Seperator>-</Seperator>
        <Number>{etrance.oneRoomCount}</Number>
      </Item>
      <Item>
        <Text>2 otaqlı mənzil</Text>
        <Seperator>-</Seperator>
        <Number>{etrance.twoRoomCount}</Number>
      </Item>
      <Item>
        <Text>3 otaqlı mənzil</Text>
        <Seperator>-</Seperator>
        <Number>{etrance.threeRoomCount}</Number>
      </Item>
      <Item>
        <Text>4 otaqlı mənzil</Text>
        <Seperator>-</Seperator>
        <Number>{etrance.fourRoomCount}</Number>
      </Item>
    </div>
  );
};

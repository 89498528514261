import pr1 from "../../../../assets/img/links/heydar_aliyev.svg"
import pr2 from "../../../../assets/img/links/prezident.svg"
import pr3 from "../../../../assets/img/links/vice_prez.svg"

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"

// import required modules
import { Autoplay, Navigation } from "swiper"

const PeopleLinks = () => {
    return (
        <>
            <div className="row justify-content-between py-3 d-none d-md-flex col-12 m-0">
                <a href="https://heydar-aliyev-foundation.org/az" target="_blank" rel="noreferrer" className="d-flex align-items-center bg-white ps-0 my-lg-0 my-md-2 my-1 col-sm-12 col-lg-3 hover-img px-2">
                    <img src={pr1} alt="Heydər Əliyev" />
                    <small className="t-color1">
                        Ümummilli lider <br /> Heydər Əliyev
                    </small>
                </a>
                <a href="https://prezident.az/" target="_blank" rel="noreferrer" className="d-flex align-items-center bg-white ps-0 my-lg-0 my-md-2 my-1 col-sm-12 col-lg-3 hover-img px-2">
                    <img src={pr2} alt="Heydər Əliyev" />
                    <small className="t-color1">
                        Azərbaycan Respublikasının <br /> Prezidenti
                    </small>
                </a>
                <a href="https://mehriban-aliyeva.az/" target="_blank" rel="noreferrer" className="d-flex align-items-center bg-white ps-0 my-lg-0 my-md-2 my-1 col-sm-12 col-lg-3 hover-img px-2">
                    <img src={pr3} alt="Heydər Əliyev" />
                    <small className="t-color1">
                        Azərbaycan Respublikasının <br /> I Vitse-Prezidenti
                    </small>
                </a>
            </div>

            <div className="d-md-none">
                {/* <div className="swiper-button-prev-n" style={{
                    height: "180px",
                    // backgroundColor: "rgba(68, 85, 114, 0.6)",
                    borderRadius: "10px"
                }}>
                    <img src={arrow1} style={{ height: "60px" }} alt="" />
                </div> */}
                <Swiper
                    loop={true}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false
                    }}
                    // navigation={{
                    //     nextEl: '.swiper-container .swiper-button-next-n',
                    //     prevEl: '.swiper-container .swiper-button-prev-n',
                    // }}
                    modules={[Autoplay, Navigation]}
                    className="mySwiper">
                    <SwiperSlide>
                        <a href="https://heydar-aliyev-foundation.org/az" target="_blank" rel="noreferrer" className="d-flex align-items-center bg-white ps-0 my-md-0 my-1 col-12 hover-img">
                            <img src={pr1} alt="Heydər Əliyev" />
                            <small className="t-color1">
                                Ümummilli lider <br /> Heydər Əliyev
                            </small>
                        </a>
                    </SwiperSlide>
                    <SwiperSlide>
                        <a href="https://prezident.az/" target="_blank" rel="noreferrer" className="d-flex align-items-center bg-white ps-0 my-md-0 my-1 col-12 hover-img">
                            <img src={pr2} alt="Heydər Əliyev" />
                            <small className="t-color1">
                                Azərbaycan Respublikasının <br /> Prezidenti
                            </small>
                        </a>
                    </SwiperSlide>
                    <SwiperSlide>
                        <a href="https://mehriban-aliyeva.az/" target="_blank" rel="noreferrer" className="d-flex align-items-center bg-white ps-0 my-md-0 my-1 col-12 hover-img">
                            <img src={pr3} alt="Heydər Əliyev" />
                            <small className="t-color1">
                                Azərbaycan Respublikasının <br /> I Vitse-Prezidenti
                            </small>
                        </a>
                    </SwiperSlide>
                </Swiper>
                {/* <div className="swiper-button-next-n" style={{
                    height: "180px",
                    // backgroundColor: "rgba(68, 85, 114, 0.6)",
                    borderRadius: "10px",
                }}>
                    <img src={arrow2} style={{ height: "60px" }} alt="" />
                </div> */}
            </div>
        </>
    )
}

export default PeopleLinks 

import { useState, useRef } from 'react'
import ReactMapGL from 'react-map-gl'
import Select from 'react-select'

const Map = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [mapData, setMapData] = useState({
        width: '100%',
        height: '100%',
        // style: 'mapbox://styles/mapbox/satellite-v9',
        latitude: 40.173,
        longitude: 47.912,
        zoom: 7
    });

    const select_options = [
        { value: 'chocolate', label: 'Chocolate', long: 48.8566, lat: 2.3522 },
        { value: 'strawberry', label: 'Strawberry', long: 48.8566, lat: 2.3522 },
        { value: 'vanilla', label: 'Vanilla', long: 48.8566, lat: 2.3522 },
    ];

    const TOKEN = "pk.eyJ1IjoiZW50b25lZSIsImEiOiJHdVBsTmdrIn0.2vXZdjRHpSQoIWrrocUnhg"

    const mapRef = useRef();

    const handleChange = (e) => {
        console.log(e)
        setSelectedOption(e.value)
        flyTo(e.long, e.lat)
    }

    const flyTo = (newLongitude, newLatitude) => {
        const map = mapRef.current.getMap();
        map.flyTo({
            center: [newLongitude, newLatitude],
            zoom: 14,
            bearing: 0,
            speed: 0.4,
            curve: 2
        });
    };

    return (
        <div style={{ height: '400px' }}>
            <ReactMapGL
                {...mapData}
                ref={mapRef}
                mapboxApiAccessToken={TOKEN}
                onViewportChange={(nextViewport) => setMapData(nextViewport)}
            />
            <Select
                value={selectedOption.label}
                onChange={handleChange}
                options={select_options}
            />
            {/* <button onClick={() => flyTo(48.8566, 2.3522)}>Fly to Paris</button> */}
        </div>
    );
};

export default Map;
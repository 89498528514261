import React from "react" 
import { Maybe } from "../core/models" 
import image from "../../assets/img/compass.png" 
import { ECompass } from "../modules/projects/floor/data/entities" 
import styled from "styled-components" 

interface IProps {
  compass: Maybe<ECompass> 
}

function compassToDeg(compass: Maybe<ECompass>): number {
  const n = parseFloat(compass ?? "0") 
  return n * 45 - 5 
}

const Image = styled.img<IProps>`
  transform: rotate(${(props) => compassToDeg(props.compass)}deg) 
` 

export const Compass: React.FC<IProps> = ({ compass }: IProps) => {
  return <Image src={image} compass={compass} width="120" /> 
} 
